
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    router.push({ path: "/fhbooking/admin" });
  },
});
